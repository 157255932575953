import axios from 'axios';
import { Message } from 'element-ui';
import { otherModule } from '@/store/modules/other';
import { appConfigModule } from '@/store/modules/appConfig';
const messageErr: string = '网络请求超时...';
console.log('===========utils/request 文件==================');
console.log(appConfigModule.appConfig.vueApi);
const service: any = axios.create({
    baseURL: appConfigModule.appConfig.vueApi
    // withCredentials: true
    // timeout: 5000
});
// 请求拦截器
service.interceptors.request.use((config: any) => {
        // 向每个请求添加X-Access-Token头，您可以在此处添加其他自定义头
        if (appConfigModule.appConfig.shopId) {
            // eslint-disable-next-line dot-notation
            config.headers['Authorization'] = 'Bearer ' + appConfigModule.appConfig.shopId;
            // eslint-disable-next-line dot-notation
            config.headers['Language'] = otherModule.language === 'zh' ? 'zh-cn' : 'zh-tw';
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use((response: any) => {
        const { Code, Msg } = response.data;
        // 根据自己实际业务判断
        if (Code === 0) {
            return Promise.resolve(response.data);
        }

        // 不出现以上code的情况下
        Message({
            showClose: true,
            message: Msg || messageErr,
            type: 'error',
            duration: 5 * 1000
        });
        return Promise.reject(Msg);
    },
    (error: any) => {
        Message({
            showClose: true,
            message: error,
            type: 'error',
            duration: 5 * 1000
        });
        console.warn(error);
        return Promise.reject(error);
    }
);

export default service;
